import {graphql} from "gatsby"
import React from "react"
import Layout from "../components/layout"

interface Props {
  readonly data: PageQueryData
  readonly pageContext: {
    previous?: any
    next?: any
  }
}

interface StaticPage {
  title?: string
  slug: string
  content: string
}

interface PageQueryData {
  site: {
    siteMetadata: {
      title: string
    }
  }
  staticPage: StaticPage
}

const ArticleTemplate: React.FC<Props> = ({data, pageContext}) => {
  const page = data.staticPage

  return (
    <Layout title={page.title}>
      <section className="container py-10 px-6">
        <div className="prose mx-auto" dangerouslySetInnerHTML={{__html: page.content}} />
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query StaticPageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    staticPage: strapiStaticPage(slug: {eq: $slug}) {
      id
      title
      content
    }
  }
`

export default ArticleTemplate
